






























import { teamsStore } from '@/store'

import { Component, Prop, Vue } from 'vue-property-decorator'
import { format } from 'quasar'

const { capitalize } = format

@Component({
  name: 'IntegrationManagementSectionGroup',
  components: {
    GroupSelector: () => import('./GroupSelector.vue'),
  },
})
export default class IntegrationManagementSectionGroup extends Vue {
  @Prop({
    type: String,
    required: true,
  }) readonly value!: string

  @Prop({
    type: Boolean,
    default: false,
  }) readonly loading!: false

  @Prop({
    type: String,
    default: '',
  }) readonly error!: string

  get groupNewText (): string {
    return capitalize(this.$t('glossary.groupNew').toString())
  }

  openChat (): void {
    if (!this.value) return
    this.$router.push({
      name: 'Chat',
      params: {
        teamId: teamsStore.getters.currentTeam.uid,
        jid: this.value,
      },
    })
    this.$emit('close')
  }
}
